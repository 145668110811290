import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/app/list'
	},
	{
		path: '/editor',
		component: () => import('@/common/MyTextarea')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login')
	},
	{
		path: '/layout',
		component: () => import('@/layout'),
		children: [
			{
				path: '/app/list',
				name: 'home',
				component: () => import('@/views/home')
			},
			{
				path: '/history',
				name: 'History',
				component: () => import('@/views/history')
			},
		]
	},
	{
		path: '/app/detail',
		name: 'Detail',
		component: () => import('@/views/detail')
	},
	{
		path: '/:unique_name',
		name: 'unique_name',
		component: () => import('@/views/detail')
	},
	{
		path: '/notFound',
		name: 'notFound',
		component: () => import('@/components/404.vue')
	}
	
]

const router = new VueRouter({
	routes,
	mode: 'history'
})

export default router
