import Vue from 'vue';
import store from '@/store';
import { Loading } from 'element-ui';
Vue.directive('myLoading',{
    bind: function(el){
        el.loading = null;
    },
    // 当被绑定的元素插入到 DOM 中时……
    update: function (el, binding, vnode, oldVnode) {
        // loading    
        // 聚焦元素
        // console.log(el);    //  元素
        // console.log(binding);   //  对象
        // console.log(vnode);     //  虚拟节点
        // console.log(oldVnode);  //  上一个虚拟节点
        // console.log(render);
        const flag = binding.value;
        if(flag){
            el.loading = Loading.service({ target: el });
        }else{
            el.loading && el.loading.close();
        }
    }
})

// 注册一个全局自定义指令 `v-hasPermit`
Vue.directive('hasPermit', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function (el, binding, vnode, oldVnode) {
        // debugger
        // loading    
        // 聚焦元素
        // console.log(el);    //  元素
        // console.log(binding);   //  对象
        // console.log(vnode);     //  虚拟节点
        // console.log(oldVnode);  //  上一个虚拟节点
        
        const permitList = store.getters.permissions;
        // console.log(permitList);
        let url = binding.value;
        // let url = el.getAttribute('url');
        
        // console.log(url,el);
        if(url === '**' || url === '' || !url){
            return;
        }
        if (url) {
            let urlList = url.split(',');
            
            let flag = permitList.find(permit => {
                if (permit.indexOf("**") != -1) {
                    permit = permit.replace("**", ".*");
                } else {
                    if (permit.indexOf("*") != -1) {
                        permit = permit.replace("*", "[a-zA-Z0-9_\\-]*");
                    }
                }
                return urlList.find(url => new RegExp(`^${permit}$`).test(url));
            })
            
            if (!flag) {      //  未拥有权限（url）
                el.parentNode.removeChild(el);
            }
        } else {
            el.parentNode.removeChild(el);
        }
    },

})
