import router from './router'
import store from './store'
import NProgress from 'nprogress'                  // progress bar
import 'nprogress/nprogress.css'                   // progress bar style


const hasConfigs = false;

NProgress.configure({ showSpinner: false })        // NProgress Configuration

router.beforeEach(async (to, _, next) => {
	NProgress.start()

	if(!hasConfigs){
		await store.dispatch('common/getConfigMap');
	}

	next()
})

router.afterEach(() => {
	NProgress.done()
})
