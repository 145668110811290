import { signIn, signOut , getUserInfo } from '@/api/login'
// import { getToken, setToken, removeToken } from '@/utils/auth'

import { JSEncrypt } from 'jsencrypt';

function encrtyFunc(value,publicKey){
	if(value.length > 128){
		return value;
	}
	// 创建JSEncrypt对象
	const encrypt = new JSEncrypt();

	// 设置公钥
	encrypt.setPublicKey(publicKey);

	// 加密密码
	var encryptedPassword = encrypt.encrypt(value);

	return encryptedPassword;
}

export const getDefaultState = () => {
	return {
		userInfo: {},
		permissions: [],
		token: '',
	}
}

const state = getDefaultState()
const mutations = {
	SET_USER_INFO: (state, userInfo) => {
		state.userInfo = userInfo
	},
	SET_PERMISSIONS: (state, permissions) => {
		state.permissions = permissions
	},
	SET_TOKEN(state,token){
		state.token = token;
	},
}

const actions = {
	// user login
	login({ rootState , state , commit , dispatch }, form) {
		return new Promise((resolve,reject) => {
			const { account, password , captcha } = form;
			let encryptPassword = password;
			const publicKey = rootState.common.configMap['whrp.user.password.rsa.public'];
			if(publicKey){
				encryptPassword = encrtyFunc(password,publicKey);
			}
			return signIn({ account , password: password , captcha: captcha || '', 'token': '' }).then(res => {
				const token = `${res.token_type} ${res.access_token}`;
				commit('SET_TOKEN', token);
				dispatch('getUserInfo');
				resolve();
			}).catch(err => {
				console.log(err);
				reject(err);
			})

		})
	},
	getUserInfo({ commit }){
		getUserInfo().then(res => {
			commit('SET_USER_INFO',res);
			commit('SET_PERMISSIONS',res.permissions);
		})
	},
	// 退出时
	logout({ commit }) {
		return signOut().then(() => {
			commit("SET_USER_INFO", {});
			commit("SET_PERMISSIONS", []);
			commit('SET_TOKEN','');
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
