import service from "@/utils/service"

export const signIn = (data) => {
	return service.post("/user/login", data,{
		headers: { "Content-Type": "application/x-www-form-urlencoded" }
	});
};

export const signOut = () => {
	return service.post("/user/logout");
};

export const getUserBaseInfo = () => {
	return service.get("/base/base-info");
};

//	验证码
export const getCode = () => {
	return service.get(`/captcha?a=${Math.random()}`,{
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		responseType: 'blob'
	});
}

//	注册
export const register = (data) => {
	return service.post('/user/register',data,{
		
	})
}
//	修改密码
export const modifyPwd = (data) => {
	return service.post('/user/mod-pwd',data,{
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
	})
}

//	用户信息
export const getUserInfo = () => {
	return service.get(`/user/base-info`,{
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		
	});
}
