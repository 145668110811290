
import { getConfigs } from '@/api/commonAPI.js';
// import { deepAssign } from '@/utils/handle.js';

const state = {
    dictMap: {
        platform_center: '',
        x: ''
    },
    configMap: {
        'whrp.user.login.captcha.enabled': '',
        'whrp.user.password.rsa.public': '',
        'whrp.app.fileType': {},
        'whrp.user.roles': {},
        'whrp.user.status': ''
        
    },
}

const mutations = {
    SET_CONFIGMAP(state,data){
        state.configMap = data;
        state.configMap['whrp.user.login.captcha.enabled'] = JSON.parse(state.configMap['whrp.user.login.captcha.enabled']);
        state.configMap['whrp.user.roles'] = JSON.parse(state.configMap['whrp.user.roles']);
        state.configMap['whrp.user.status'] = JSON.parse(state.configMap['whrp.user.status']);
    }
}

const actions = {
    getDictMap({ state }){
        return getDict({ category: Object.keys(state.dictMap).join(',') }).then(res => {
            Object.keys(state.dictMap).forEach((key,index) => {
                state.dictMap[key] = res[index];
            })
        })
    },
    getConfigMap ({ state , commit }) {
        return new Promise((resolve, reject) => {
            const configMap = state.configMap;
            const keys = Object.keys(configMap).join(',');
            getConfigs({ keys }).then(res => {
                commit('SET_CONFIGMAP',res);
                resolve();
            }).catch(err => {
                console.log(err);
                resolve();
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
