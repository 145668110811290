import axios from "axios"
import router from '@/router';
import { Message } from 'element-ui'
import store from '@/store';

const service = axios.create({
	baseURL: "/api",
	timeout: 5000,
})

// const TOEKN = true;
service.interceptors.request.use(
	config => {
		const token = store.getters.token;
		// console.log(token);
		if(token){
			config.headers['Authorization'] = token;
		}

		if (config.headers["Content-Type"] === "application/x-www-form-urlencoded") {
			let form = new URLSearchParams();
			for (const key in config.data) {
				form.append(key, config.data[key]);
			}
			config.data = form;
			return config;
		}
		if (config.headers['Content-Type'] === 'multipart/form-data') {
			let form = new FormData();
			for (const key in config.data) {
				form.append(key, config.data[key]);
			}
			config.data = form;
		}

		return config;
	}, err => {
		console.log(err);
		return Promise.reject(err);
	}
);

service.interceptors.response.use(
	response => {
		// console.log(response);
		return response.data;
	}, err => {
		console.log(err);
		const code = err.response && err.response.status;
		// const data = err.response.data;
		// Message.error(data.message);
		console.log(err.response);
		if(err.response && err.response.data.message){
			Message.error(err.response.data.message);
		}

		if (code === 401) {	
			// console.log(router.currentRoute);
			const fullPath = router.currentRoute.fullPath;


			router.push({ 
				name: "login",
				query: {
					redirectUrl: fullPath
				}
			})
			return Promise.reject(err.response)
		}

		return Promise.reject(err);
	}
);

export default service;
