import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)
// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
	// set './app.js' => 'app'
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
	const value = modulesFiles(modulePath)
	modules[moduleName] = value.default
	return modules
}, {})

const store = new Vuex.Store({
	modules,
	getters,
	plugins: [
        persistedState({
        //     //默认使用localStorage来固化数据，也可使用sessionStorage，配置一样
            storage: window.localStorage,
            reducer(val) {
				// console.log(val);
                return {
                    // 只储存state中的值
                    // currentCase: val.currentCase,
					user: {
						token: val.user.token,
						userInfo: val.user.userInfo,
						permissions: val.user.permissions,
					},
					common: {
						configMap: val.common.configMap
					}
                }
            }
        })
    ]
})

export default store
